@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

body {
  margin: 0;
  padding: 0;
}

/* Style the scroll container */
.table-container {
  overflow-x: auto;
  /* Enable horizontal scrolling */
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #888 #f1f1f1;
  /* For Firefox */
}

/* Custom scrollbar for WebKit browsers (Chrome, Edge, Safari) */
.table-container::-webkit-scrollbar {
  height: 8px;
  /* Horizontal scrollbar height */
  width: 8px;
  /* Vertical scrollbar width */
}

/* Scrollbar track */
.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Light gray background */
  border-radius: 10px;
}

/* Scrollbar thumb */
.table-container::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  /* Darker gray thumb */
  border-radius: 10px;
}

/* Scrollbar thumb hover */
.table-container::-webkit-scrollbar-thumb:hover {
  background: #959595;
  /* Even darker gray on hover */
}